<template>
  <div>
    <CDataTable
            :items="items"
            :fields="fields"
            hover
            border
    >
      <template #value="{item}">
        <td>
          <code class="dns-value-copy">
            {{ item.value }}
          </code>
        </td>
      </template>
      <template #valid="{item}">
        <td>{{ item.valid === 'valid' ? 'Ja' : 'Nein' }}</td>
      </template>
    </CDataTable>
  </div>
</template>

<script>
  export default {
    name: 'MailgunDnsEntries',
    props: {
      mailgunResponseJson: {
        type: [String, Object],
        default: '{}'
      },
    },
    data () {
      return {
        fields: [
          { key: 'record_type', label: 'Typ', _style:'min-width:20px' },
          { key: 'name', label: 'Name', _style:'min-width:200px' },
          { key: 'value', label: 'Inhalt', _style:'min-width:200px' },
          // { key: 'priority', label: 'Priorität', _style:'min-width:20px' },
          { key: 'valid', label: 'Bereits gesetzt?', _style:'min-width:20px' },
        ]
      }
    },
    computed: {
      items() {
        const mailgunResponse = typeof this.mailgunResponseJson === 'string' ? JSON.parse(this.mailgunResponseJson) : this.mailgunResponseJson
        return mailgunResponse ? mailgunResponse.sending_dns_records : [];
      }
    }
  }
</script>

<style>
  .dns-value-copy {
    user-select: all;
    word-break: break-all;
    width: 100%;
  }
</style>
