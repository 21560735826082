<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Grundeinstellungen</strong><br />
              <DoubleBounceLoader v-if="loading || saving" width="20px" height="20px" style="float: right"/>
            </slot>
          </CCardHeader>
          <CCardBody v-if="!loading">
            <CTabs>
              <CTab title="Unternehmen" active>
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                     <CInput
                            type="text"
                            label="Name"
                            description="Ihr Unternehmensname"
                            v-model="currentRealm.name"
                            horizontal
                    />
                    <CInput
                            type="text"
                            label="Straße und Hausnummer"
                            description="Geben Sie die Straße und die Hausnummer Ihres Unternehmens an."
                            v-model="companySettings.streetAndNumber"
                            horizontal
                    />
                    <CInput
                            type="text"
                            label="PLZ"
                            description="Geben Sie die Postleitzahl Ihres Unternehmens an."
                            v-model="companySettings.postalCode"
                            horizontal
                    />
                    <CInput
                            type="text"
                            label="Ort"
                            description="Geben Sie den Ort Ihres Unternehmens an."
                            v-model="companySettings.city"
                            horizontal
                    />
                    <CInput
                            type="text"
                            label="Telefon"
                            description="Geben Sie die Telefonnummer Ihres Unternehmens an."
                            v-model="companySettings.phoneNumber"
                            horizontal
                    />
                    <CInput
                            type="text"
                            label="Fax"
                            description="Geben Sie die Faxnummer Ihres Unternehmens an."
                            v-model="companySettings.fax"
                            horizontal
                    />
                    <CInput
                            type="text"
                            label="Webseite"
                            description="Geben Sie die URL der Webseite Ihres Unternehmens an."
                            v-model="companySettings.website"
                            horizontal
                    />
                    <CInput
                            type="text"
                            label="E-Mail"
                            description="Geben Sie die E-Mail Adresse Ihres Unternehmens an."
                            v-model="companySettings.email"
                            horizontal
                    />
                    <CInput
                            type="text"
                            label="Kontaktperson"
                            description="Geben Sie die Kontaktperson Ihres Unternehmens an."
                            v-model="companySettings.contactPerson"
                            horizontal
                    />
                    <CInput
                            type="text"
                            label="Kontaktperson: Position"
                            description="Geben Sie die Position der Kontaktperson Ihres Unternehmens an. (zB. CEO)"
                            v-model="companySettings.contactPersonPosition"
                            horizontal
                    />
                    <!--<CInput
                            type="text"
                            label="WordPress URL"
                            description="Falls Sie das WordPress Plugin verwenden und Zapier nutzen wird diese URL verwendet um auch das Plugin über neue Leads zu informieren"
                            v-model="currentRealm.wordpressUrl"
                            horizontal
                    />-->
                    <CInput
                            type="text"
                            label="Provision/Gewinn"
                            description="Geben Sie die Provision oder den prozentualen Gewinn in Prozent pro Lead an."
                            v-model="currentRealm.commission"
                            horizontal
                    />
                    <CInput
                            type="text"
                            label="Erlaubte Domains"
                            description="Bitte geben Sie die erlaubten Domains in einer durch Komma getrennten Weise ein, welche Ihren Leadgenerator einbetten dürfen."
                            v-model="currentRealm.allowedDomains"
                            horizontal
                    />
                    <Checkbox
                            label="Zapier aktiv?"
                            name="zapierActive"
                            :options="['Aktiv']"
                            v-model="currentRealm.zapierSubscribe === null ? '': 'Aktiv'"
                            :description="computedZapierDescription"
                    />
                    <CInput
                            type="text"
                            label="API-Schlüssel"
                            class="api-key-input"
                            :readonly="true"
                            description="Verwenden Sie Ihren Marketing-Cloud API-Schlüssel, um auf die API zuzugreifen. Mit diesem Schlüssel haben Entwickler uneingeschränkten Zugriff, um über die Marketing-Cloud-API Änderungen an diesem Account vorzunehmen, einschließlich des Lesens von Leads und anderer Daten von Drittanbietern. Behandeln Sie Ihren API-Schlüssel vertraulich und geben Sie ihn nicht öffentlich weiter"
                            :value="currentRealm.apiKey"
                            horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab title="E-Mail-Konfiguration">
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <CInput
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                            type="text"
                            label="Domain"
                            placeholder=""
                            :isValid="validFields.mailgunDomain.valid"
                            :invalidFeedback="validFields.mailgunDomain.invalidFeedback"
                            v-model="currentRealm.mailgunDomain"
                            :disabled="currentRealm.mailgunDomainResponseJson != null"
                            slots="collapse"
                            horizontal
                    >
                      <template v-slot:description>
                        <small class="form-text text-muted w-100">Standardmäßig werden Ihre E-Mails über die Makler-Anfragen Mailer-Domain (mg.makler-anfragen.immo) gesendet. <br />Dies ist die einfachste Option und erfordert keine Konfiguration. <br />Wenn Sie eine Domain haben, von der aus Sie Ihre Nachrichten senden möchten, können Sie eine benutzerdefinierte Domain hinzufügen.</small>
                        <br>
                        <a v-if="currentRealm.mailgunDomainResponseJson" href="#" @click.prevent="collapseMailgunDns = !collapseMailgunDns">DNS-Einträge anzeigen</a>
                        <template v-if="collapseMailgunDns && currentRealm.mailgunDomainResponseJson">
                          <span style="margin: 0 8px">|</span>
                          <a href="#" @click.prevent="verifyAndRefreshMailgunDomain">DNS-Einträge prüfen</a>
                          <span style="margin: 0 8px">|</span>
                          <a href="#" @click.prevent="deleteMailgunDomain" style="color: darkred" >Domain löschen</a>
                        </template>
                        <CCollapse v-if="currentRealm.mailgunDomainResponseJson" :show="collapseMailgunDns">
                            <MailgunDnsEntries style="margin-top: 10px" :mailgun-response-json="currentRealm.mailgunDomainResponseJson"/>
                        </CCollapse>
                      </template>
                    </CInput>
                    <hr/>
                    <RadioButton
                        label="Aktivitäten via E-Mail an mich senden?"
                        name="email_activities"
                        description="Möchten Sie über die Öffnung der Immobilienbewertung durch den Eigentümer, zusätzlich per E-Mail benachrichtig werden?"
                        :options="[{label: 'Ja', value: true}, {label: 'Nein', value: false}]"
                        v-model="emailSettings.activities"
                    />
                    <CInput
                            type="text"
                            label="Admin E-Mail Adresse"
                            description="An diese E-Mail werden die Benachrichtigungen über neue Leads gesendet. Ausserdem wird diese E-Mail-Adresse als Standard Absender für alle E-Mails verwendet. Format: Ihr Name <info@mustermakler.de> - Beispiel: Mustermakler GmbH <info@mustermakler.de>"
                            placeholder="Ihr Name <ihre@email.de>"
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                            v-model="emailSettings.adminEmail"
                            horizontal
                    />
                    <CInput
                            type="text"
                            label="E-Mail: Betreff (Kunde)"
                            description="Betreff der Wertermittlungs E-Mail an den Eigentümer. Die Verwendung der unten angegeben Platzhalter ist möglich."
                            v-model="emailSettings.customerSubject"
                            horizontal
                    />
                    <WysiwygEditor
                            label="E-Mail: Inhalt (Kunde)"
                            description="Inhalt der Wertermittlungs E-Mail an den Eigentümer. Die Verwendung der unten angegeben Platzhalter ist möglich."
                            v-model="emailSettings.customerContent"
                            horizontal
                    />
                    <WysiwygEditor
                            label="E-Mail: Inhalt (Kunde) (bei keinen Ergebnissen)"
                            description="Inhalt der E-Mail an den Eigentümer wenn keine Ergebnisse vorliegen oder die Wertermittlung deaktiviert ist. Die Verwendung der unten angegeben Platzhalter ist möglich."
                            v-model="emailSettings.customerContentNoResults"
                            horizontal
                    />
                    <FileUploadField
                            label="E-Mail: Dateianhang (Kunde)"
                            description="Fügen Sie einen Dateianhang hinzu, dieser wird den Mails an Ihre Kunden angehangen. (Kommt bald)"
                            v-model="emailSettings.customerAttachment"
                            settingKey="email_customerAttachment"
                    /><br>
                    <hr/>
                    <CInput
                            type="text"
                            label="E-Mail: Betreff (Admin)"
                            description="Ändern Sie den Betreff der E-Mail, welche an Sie selbst versendet wird. Die Platzhalter (unten) sind erlaubt."
                            v-model="emailSettings.adminSubject"
                            horizontal
                    />
                    <WysiwygEditor
                            label="E-Mail: Inhalt (Admin)"
                            description="Ändern Sie den Inhalt der E-Mail, welche an Sie selbst versendet wird. Sie können diese Platzhalter unten nutzen."
                            v-model="emailSettings.adminContent"
                            horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab title="OpenImmo" v-if="shouldShowImmoTab">
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <CInput
                            type="text"
                            label="OpenImmo Objekt Nr."
                            description="Diese Nummer wird zur Zuordnung des Leads beim Import der OpenImmo XML in Ihrer Immobiliensoftware benötigt"
                            v-model="openImmoSettings.objectId"
                            horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab title="Opt-in" v-if="isNotKensingtonOrIsButOrgaAdmin">
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <CInput
                            type="text"
                            label="Opt-in E-Mail Betreff"
                            description="Geben Sie für Ihre E-Mail einen Betreff ein. Die Platzhalter (unten) sind erlaubt."
                            v-model="optInSettings.emailSubject"
                            horizontal
                    />
                    <WysiwygEditor
                            label="Opt-in E-Mail Inhalt"
                            description="Geben Sie den Inhalt der E-Mail-Bestätigungs-Mail ein. Die Platzhalter (unten) sind erlaubt."
                            v-model="optInSettings.emailContent"
                            horizontal
                    />
                    <WysiwygEditor
                            label="Opt-In akzeptiert"
                            description="Ändern Sie den Inhalt der Bestätigungsseite ein, nachdem auf den 'Verifizieren' Button geklickt hat. HTML ist erlaubt."
                            v-model="optInSettings.acceptedHtml"
                            horizontal
                    />
                    <WysiwygEditor
                            label="Opt-In annulliert"
                            description="Ändern Sie den Inhalt der Seite, die dem Kunden bei Abmeldung aus dem E-Mail-Verteiler angezeigt wird. HTML ist erlaubt."
                            v-model="optInSettings.cancelledHtml"
                            horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab title="AGB & Datenschutz" v-if="isNotKensingtonOrIsButOrgaAdmin">
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <WysiwygEditor
                        label="AGB"
                        horizontal
                        description="Erstellen Sie die Allgemeinen Geschäftsbedigungen für dieses Unternehmen."
                        v-model="companySettings.conditions"
                    />
                    <WysiwygEditor
                        label="Datenschutz"
                        horizontal
                        description="Erstellen Sie die Datenschutzerklärung für dieses Unternehmen."
                        v-model="companySettings.privacy"
                    />
                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
            <CButton @click="submitSettings" color="success">Speichern</CButton>
            <CButton
                v-if="$commons.isOrganizationAdmin()"
                @click="submitSettings(true)"
                style="margin-left: 6px"
                v-c-tooltip="'Legen Sie die aktuellen Einstellungen als Standard für alle zukünftigen Standorte fest'"
                color="warning">
              Als Standard definieren & Speichern
            </CButton>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Platzhalter für E-Mails</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <table class="vars-to-use" border="0" v-pre>
              <tbody>
                <tr>
                  <td>{{MAKLER}}</td>
                  <td>Ihr Maklername</td>
                </tr>
                <tr>
                  <td>{{IMMOBILIEN_TYP}}</td>
                  <td>Z.b. Haus, Eigentumswohnung etc.</td>
                </tr>
                <tr>
                  <td>{{ANREDE}}</td>
                  <td>Anrede </td>
                </tr>
                <tr>
                  <td>{{NACHNAME}}</td>
                  <td>Nachname des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{VORNAME}}</td>
                  <td>Vorname des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{TELEFON}}</td>
                  <td>Telefonnummer des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{EMAIL}}</td>
                  <td>E-Mail Adresse des Eigentümers</td>
                </tr>
                <tr>
                  <td>{{ADRESSE}}</td>
                  <td>Vollständige Anschrift der Immobilie</td>
                </tr>
                <tr>
                  <td>{{WOHNFLACHE}}</td>
                  <td>Wohnfläche der Immobilie</td>
                </tr>
                <tr>
                  <td>{{ZIMMER}}</td>
                  <td>Anzahl der Zimmer der Immobilie</td>
                </tr>
                <tr>
                  <td>{{BAUJAHR}}</td>
                  <td>Baujahr der Immobilie</td>
                </tr>
                <tr>
                  <td>{{GRUNDFLACHE}}</td>
                  <td>Grundstücksfläche der Immobilie</td>
                </tr>
                <tr>
                  <td>{{ETAGE}}</td>
                  <td>Anzahl der Etagen der Immobilie</td>
                </tr>
                <tr>
                  <td>{{ERSCHLOSSEN}}</td>
                  <td>Bei Grundstücken, angabe ob Erschlossen, Teilerschlossen oder nicht erschlossen</td>
                </tr>
                <tr>
                  <td>{{BEBAUUNG}}</td>
                  <td>Bebaungsmöglichkeiten von Grundstücken</td>
                </tr>
                <tr>
                  <td>{{ZUSCHNITT}}</td>
                  <td>Angaben zum Grundstückszuschnitt</td>
                </tr>
                <tr>
                  <td>{{GRUND}}</td>
                  <td>Grund der Immobilienbewertung Kauf oder Verkauf</td>
                </tr>
                <tr>
                  <td>{{GRUND_WANN}}</td>
                  <td>Verkaufszeitwunsch, wenn "Verkauf" als Grund ausgewählt</td>
                </tr>
                <tr>
                  <td>{{GESAMT_ERGEBNIS}}</td>
                  <td>Ermittelter durchschnittlicher Marktwert</td>
                </tr>
                <tr>
                  <td>{{MIN_GESAMT_ERGEBNIS}}</td>
                  <td>Resultierende Wertspanne minimum</td>
                </tr>
                <tr>
                  <td>{{MAX_GESAMT_ERGEBNIS}}</td>
                  <td>Resultierende Wertspanne maximum</td>
                </tr>
                <tr>
                  <td>{{ERGEBNIS_PRO_QM}}</td>
                  <td>Durchschnittlicher Wert pro m² Wohnfläche</td>
                </tr>
                <tr>
                  <td>{{MIN_ERGEBNIS_PRO_QM}}</td>
                  <td>Ermittelte Wertspanne pro m² minimum</td>
                </tr>
                <tr>
                  <td>{{MAX_ERGEBNIS_PRO_QM}} </td>
                  <td>Ermittelte Wertspanne pro m² maximum</td>
                </tr>
                <tr>
                  <td>{{OPT_IN_CONFIRM_URL}} </td>
                  <td>Die URL welche aufgerufen werden muss, damit die Kund:innen E-Mails erhalten dürfen. <b>Nur in der Opt-In E-Mail nutzbar!</b></td>
                </tr>
                <tr>
                  <td>{{PDF_URL}} </td>
                  <td>Link zur ursprünglichen Wohnmarktanalyse, falls vorhanden. <b>Nicht in der Opt-In E-Mail nutzbar!</b></td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
  import DoubleBounceLoader from '@/components/loaders/DoubleBounceLoader'
  import WysiwygEditor from '@/components/WysiwygEditor'
  import Checkbox from '@/components/Checkbox'
  import RadioButton from '@/components/RadioButton'
  import commons from '@/commons'
  import axios from 'axios'
  import _ from 'lodash'
  import MailgunDnsEntries from '@/components/MailgunDnsEntries'
  import validations from '@/validations'
  import FileUploadField from "@/components/FileUploadField";

  export default {
  name: 'RealmSettings',
  components: {
    FileUploadField,
    MailgunDnsEntries,
    RadioButton,
    DoubleBounceLoader,
    WysiwygEditor,
    Checkbox
  },
  data () {
    return {
      loading: false,
      saving: false,
      currentRealm: this.$commons.getFromStorage('currentRealm'),
      collapseMailgunDns: false,
      /* v-if explained: if realmPlain is NOT KENSINGTON then show, else: check if hasRole minimum ORGANIZATION_ADMIN */
      isNotKensingtonOrIsButOrgaAdmin: !commons.hasRealmPlan('KENSINGTON') || commons.hasRole('ORGANIZATION_ADMIN'),
      companySettings: {
        streetAndNumber: '',
        postalCode: '',
        city: '',
        phoneNumber: '',
        fax: '',
        website: '',
        email: '',
        contactPerson: '',
        contactPersonPosition: '',
        conditions: '',
        privacy: '',
      },
      emailSettings: {
        activities: false,
        adminEmail: '',
        customerSubject: '',
        customerContent: '',
        customerContentNoResults: '',
        customerAttachment: '',
        feedbackEmailActive: false,
        feedbackEmailSubject: '',
        feedbackEmailContent: '',
        feedbackSendAfter: 10,
        adminSubject: '',
        adminContent: ''
      },
      openImmoSettings: {
        objectId: '',
      },
      optInSettings: {
        emailSubject: '',
        emailContent: '',
        acceptedHtml: '',
        cancelledHtml: ''
      },
      validFields: {
        mailgunDomain: {
          valid: null,
          invalidFeedback: ''
        }
      },
      debounced: {
        validateMailgunDomain: _.debounce(this.validateMailgunDomain, 500)
      }
    }
  },
  mounted () {
    this.loading = true
    axios.get('/settings')
        .then(response => {
          this.emailSettings = _.merge({}, this.emailSettings, commons.deepenOneLevelToNested(response.data, '_', 'email_'))
          this.openImmoSettings = _.merge({}, this.openImmoSettings, commons.deepenOneLevelToNested(response.data, '_', 'openimmo_'))
          this.optInSettings = _.merge({}, this.optInSettings, commons.deepenOneLevelToNested(response.data, '_', 'optIn_'))
          this.companySettings = _.merge({}, this.companySettings, commons.deepenOneLevelToNested(response.data, '_', 'company_'))
          this.loading = false
        })
        .catch(err => console.error(err))
  },

  computed: {
    shouldShowImmoTab() {
      const currentPlanName = this.currentRealm.plan;
      const isOrgAdmin = commons.isOrganizationAdmin();
      const isKensington = currentPlanName === 'KENSINGTON';
      const containsImmo = currentPlanName.includes('Immo');
      return (isKensington && isOrgAdmin) || containsImmo;
    },

    computedZapierDescription() {
      return this.$commons.hasRealmPlan('PREMIUM')
          ? 'Wenn Sie Zapier verwenden, ist diese Checkbox aktiv.'
          : 'Um diese Funktion nutzen zu können, benötigen Sie den "PREMIUM" Tarif.'
    }
  },
  methods: {
    submitSettings(sendAsDefault) {
      this.saving = true
      let toSubmit = commons.oneLevelObject({
        email: this.emailSettings,
        openimmo: this.openImmoSettings,
        optIn: this.optInSettings,
        company: this.companySettings
      }, '_')
      this.$modal.showModalOnly('loading')

      const url = sendAsDefault === true ? '/settings/default' : '/settings'
      axios.post(url, toSubmit)
          .then(response => {
            this.saving = false
          })
          .catch(err => {
            this.$toaster.makeToast('Fehler', 'Ihre Einstellungen konnten nicht gespeichert werden! Mehr dazu in der Console.')
            this.saving = false
            console.error(err, err.response.data)
          })
      axios.post('/realm/current', { ...this.currentRealm, organizationId: this.currentRealm.organization.id })
          .then(response => {
            const data = { ...response.data }
            commons.setToStorage('currentRealm', data)
            this.currentRealm = data
            this.$modal.hideModal('loading')
            this.$toaster.makeToast('Einstellungen erfolgreich gespeichert', 'Ihre Einstellungen wurden erfolgreich gespeichert.')
          })
          .catch(err => {
            this.$toaster.makeToast('Fehler', 'Ihre Realm Einstellungen konnten nicht gespeichert werden! Mehr dazu in der Console.')
            console.error(err, err.response.data)
          })
      this.syncSettingsWithPdfGen()
    },
    validateMailgunDomain(val) {
      if (validations._allowEmptyString(val)) {
        this.validFields.mailgunDomain.valid = null
        return
      }
      if (!validations.domain(val)) {
        this.validFields.mailgunDomain.valid = false
        this.validFields.mailgunDomain.invalidFeedback = 'Die angegebene Domain ist ungültig.'
        return
      }
      axios.get(`/realm/validateMailgunDomain/${val}`)
        .then(response => {
          const valid = response.data.valid
          if (valid) {
            this.validFields.mailgunDomain.valid = true
          } else {
            this.validFields.mailgunDomain.valid = false
            this.validFields.mailgunDomain.invalidFeedback = 'Die Domain wurde bereits registriert.'
          }
        })
        .catch(err => {
          this.validFields.mailgunDomain.valid = false
          this.validFields.mailgunDomain.invalidFeedback = 'Fehler bei der Überprüfung.'
        })
    },
    verifyAndRefreshMailgunDomain () {
      this.$modal.showModalOnly('loading')
      axios.get('/realm/current/verifyAndRefreshMailgunDomain')
        .then(response => {
          this.currentRealm.mailgunDomainResponseJson = { ...response.data }
          commons.setToStorage('currentRealm', { ...this.currentRealm })
          this.$modal.hideModal('loading')
          this.$toaster.makeToast('Aktualisiert', 'Die DNS-Einträge wurden aktualisiert. Bitte prüfen.')
        })
        .catch(err => {
          this.$toaster.makeToast('Fehler!', err.message)
        })
    },
    deleteMailgunDomain() {
      this.$modal.showModal(
          'default',
          'E-Mail Domain löschen?',
          [
            `Möchten Sie Ihre E-Mail Domain aus unseren Systemen löschen?`,
          ],
          async () => {
            try {
              let resp = await axios.delete(`/realm/current/deleteMailgunDomain`)
              this.currentRealm.mailgunDomain = null
              this.currentRealm.mailgunDomainResponseJson = null
              commons.setToStorage('currentRealm', { ...this.currentRealm })
              this.$toaster.makeToast('Erfolgreich!', 'Die E-Mail Domain wurde soeben gelöscht.')
            } catch (e) {
              this.$toaster.makeToast('Fehler!', 'Die E-Mail Domain konnte nicht gelöscht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.')
              console.error(e)
            }
          }
      )
    },
    syncSettingsWithPdfGen() {
      axios.get('/pdf/syncSettings?includeMedia=false')
          .catch(err => {
            this.$toaster.makeToast('Fehler', 'Ihre Einstellungen konnten nicht mit dem PDF Server synchronisiert werden! Mehr dazu in der Console.')
            console.error(err)
          })
    }
  },
  watch: {
    'currentRealm.mailgunDomain': function(newVal, oldVal) {
      this.debounced.validateMailgunDomain(newVal)
    }
  }
}
</script>

<style scoped>
  .vars-to-use {
    font-style: italic;
    font-weight: 400;
    opacity: 0.85;
  }
  .big-textarea {
    height: 150px;
  }
</style>